import React, { Component } from "react";
import { Container, Row } from "reactstrap";
import SectionTitle from "../common/section-title";

class WebsiteDesc extends Component {
  render() {
    const desc = "PrivKey is a leading provider of cybersecurity and blockchain solutions, with 29+ years of experience in the networking, cybersecurity, and internet fields. We specialize in providing expert consulting and development services for businesses and organizations looking to leverage the benefits of blockchain technology, including smart contract development, multisig wallet management, and compliance with regulatory standards. Our team has extensive experience in developing smart contracts for various blockchains, and we offer a range of security solutions for cryptocurrency wallets, including multi-sig implementation, hardware wallet setup, and management, and regular security audits.<br></br> We also provide cybersecurity services, such as threat intelligence, penetration testing, incident response, security consulting, and training, to ensure the security of our clients' networks, systems, and assets. Our compliance and regulatory support services provide guidance on compliance with AML/KYC regulations and other relevant laws, and our IT services support and design team offers high-quality systems, network, virtual infrastructure, and cloud solutions to meet varying business requirements.<br></br>At PrivKey, we pride ourselves on providing tailor-made solutions to meet the specific needs of our clients, while ensuring compliance with the laws and regulations of their respective jurisdictions.";

    return (
      <React.Fragment>
        <section className="section " id="who-we-are">
          <Container>
            <SectionTitle
              title="WHO WE ARE"
              desc={<p style={{color: 'black'}} dangerouslySetInnerHTML={{__html: desc}} />}
            />
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default WebsiteDesc;