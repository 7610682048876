import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import ReactTypingEffect from 'react-typing-effect-multiline';


class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.callModal.bind(this);
  }

  callModal = () => {
    this.refs.child.openModal();
  };
  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-home vh-100"
          id="home"
          data-image-src="images/bg-home.jpg"
        >
          <div className="bg-overlay"></div>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row>
                  <Col
                    lg={{ size: 8, offset: 2 }}
                    className="text-white text-center"
                  >
                    <h1 className="home-title">
                      <ReactTypingEffect
                        text={["Safeguard Your Enterprise: Cutting-Edge Cybersecurity & Blockchain Solutions for a Secure Business"]}
                        typingDelay={1000}
                        speed={100}
                        cursor={""}
                      />
                    </h1>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
