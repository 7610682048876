import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import TeamBox from "./TeamBox";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: [
        {
          image: "assets/images/william_profile.png",
          title: "William K. Santiago",
          desc: "FOUNDER & CEO",
          linkedIn: "https://linkedin.com/in/wksantiago",
          twitter: "https://twitter.com/williamsantiago",
        },
        {
          image: "assets/images/kyle_profile.png",
          title: "Kyle W. Santiago",
          desc: "CO-FOUNDER",
          linkedIn: "https://linkedin.com/in/kwsantiago",
          twitter: "https://twitter.com/kwsantiago",
        },
        // {
        //   image: "assets/images/developer.png",
        //   title: "Vacant Position",
        //   desc: "DEVELOPER",
        //   linkedIn: "https://linkedin.com/in/developer",
        //   twitter: "",
        // },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-back section-title-white" id="team">
          <Container>
            {/* section title */}
            <SectionTitle
              title="MEET OUR TEAM"
              desc="We invest in ongoing research and development to stay at the forefront of the industry. Our team of experts, including prominent recognized industry network security advisors, ensures that our services are up-to-date and in line with the latest industry developments and best practices. We are committed to providing cutting-edge solutions to our clients, and our R&D efforts are an essential part of achieving that goal. We also stay informed about the latest regulations and compliance requirements in the field of cybersecurity and blockchain technology, to ensure that our services are always compliant with the laws and regulations of our clients' respective jurisdictions."
            />
            <div className="margin-above-sub"></div>
            <Row>
              {/* team box */}
              <TeamBox teams={this.state.teams} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Clients;