import React, { Component } from "react";
import { Container, Row, Col} from "reactstrap";
import SectionTitle from "../common/section-title";

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const desc="Get in touch with us today by filling out the form below. We look forward to connecting with you and will respond as quickly as possible."

    return (
      <React.Fragment>
        <section className="section " id="contact">
          <Container>
            {/* section title */}
            <SectionTitle
              title="GET IN TOUCH"
              desc={<p style={{color: 'black'}} dangerouslySetInnerHTML={{__html: desc}} />}
            />

            <Row>
              <Col lg={4}>
                <div className="mt-4 pt-4">
                  <p className="mt-4">
                    <span className="h5">Office Address:</span>
                    <br />{" "}
                    <span className="text-black d-block mt-2">
                      Florida, USA
                    </span>
                  </p>
                  <p className="mt-4">
                    <span className="h5">Working Hours:</span>
                    <br />{" "}
                    <span className="text-black d-block mt-2">
                      9:00 AM To 4:00 PM EST
                    </span>
                  </p>
                  <a href="https://www.designrush.com/agency/cybersecurity/florida">
                    <img src="/assets/images/cybersecurity-companies.png" alt="Top Florida Cybersecurity Companies Rankings"/></a>
                </div>
              </Col>
              <Col lg={8}>
                <div className="custom-form mt-4 pt-4">
                  <div id="message"></div>
                  <form action="https://formspree.io/f/mwkwqwkl" method="POST">
                    <Row>
                      <Col lg={6}>
                        <label>Name</label>
                        <input
                          required
                          name="name"
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder="Your name*"
                          label="Name"
                        />
                      </Col>
                      <Col lg={6}>
                        <label>Email</label>
                        <input
                          required
                          name="email"
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="Your email*"
                          label="Email"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <label>How did you hear about us?</label>
                        <select
                          required
                          name="Referral"
                          label="How did you hear about us?"
                          defaultValue="Friends"
                          className="is-untouched is-pristine av-valid form-control"
                        >
                          <option>Friends</option>
                          <option>Search Engine</option>
                          <option>Advertisement</option>
                          <option>Other</option>
                        </select>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <label>Message</label>
                        <textarea
                          required
                          name="comments"
                          id="comments"
                          rows="4"
                          className="form-control"
                          placeholder="Drop us a line..."
                        ></textarea>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} className="text-end">
                        <input
                          type="submit"
                          id="submit"
                          name="send"
                          className="submitBnt btn btn-primary"
                        />
                      </Col>
                    </Row>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
