import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services1: [
        {
          icon: "pe-7s-safe",
          title: "DLT & Blockchain Consulting",
          desc:
            "Expert consulting and development services for businesses and organizations looking to leverage the benefits of Distributed Ledger Technology (DLT) and blockchain technology, including blockchain strategy, design, and implementation, as well as advice on the use of Central Bank Digital Currency (CBDCs) and Security Token Offerings (STOs).",
        },
        {
          icon: "pe-7s-plugin",
          title: "Smart Contract Development",
          desc:
            "Our expert developers specialize in creating and auditing secure, reliable smart contracts for various blockchain platforms. We work closely with clients to understand their needs and design solutions that comply with industry standards and best practices. Our comprehensive auditing process identifies potential vulnerabilities, and we have the expertise to build or audit smart contracts for Ethereum, RSK, Solana, and more.",
        },
        {
          icon: "pe-7s-lock",
          title: "Cybersecurity Services",
          desc:
            "We provide comprehensive cybersecurity services to ensure the security of our clients' networks, systems, and assets, including threat intelligence, penetration testing, incident response, security consulting, and training. Our team of experts offers real-time monitoring, simulation of real-world hacking scenarios, and expert advice to mitigate security risks.",
        },
      ],
      services2: [
        {
          icon: "pe-7s-wallet",
          title: "Wallet Security & Key Management",
          desc:
            "Wallet security, private key management, and Multi-Party Computation (MPC) based asset management and security. This includes a Multisig concierge service to help businesses take control of their private/public keys, including step-by-step instructions and best-practice operational security consulting.",
        },
        {
          icon: "pe-7s-tools",
          title: "Complete IT Solutions",
          desc:
            "Design, implement and integrate high-quality systems, network, cloud solutions. Ensure compliance with AML/KYC regulations, and provide AI-powered threat detection and response to potential cybersecurity risks, and integration of AI into blockchain for improved efficiency and security.",
        },
        {
          icon: "pe-7s-note2",
          title: "Auditing & Certification",
          desc:
            "We provide auditing and certification services to help our clients achieve compliance with the CryptoCurrency Security Standard (CCSS) and implement best practices for securing their cryptocurrency assets. Our services complement existing information security standards and provide guidance for securing cryptocurrencies like Bitcoin.",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className={"section bg-back section-title-white"} id="services" >
          <Container>
            {/* section title */}
            <SectionTitle
              title="Our Services"
              desc="PrivKey provides a range of services including cybersecurity, blockchain and distributed ledger technology consulting and implementation, smart contract development, multisig concierge service, and private key management solutions. These solutions are intended for businesses and organizations looking to leverage the benefits of blockchain technology in a safe, reliable, and compliant manner."
              />

            <Row className="mt-4">
              {/* services box */}
              <ServiceBox services={this.state.services1} />
            </Row>

            <Row className="mt-4">
              {/* service box */}
              <ServiceBox services={this.state.services2} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
