import React, { Component } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import Service from "../../components/Service/service";
// import AboutUs from "../../components/AboutUs/AboutUs";
import WebsiteDesc from "../../components/WebsiteDesc/WebsiteDesc";
// import Pricing from "../../components/Pricing/pricing";
import Team from "../../components/Team/Team";
// import Process from "../../components/Process/Process";
// import Testimonials from "../../components/Testimonials/Testimonials";
// import GetStart from "../../components/GetStart/GetStart";
// import Blog from "../../components/Blog/Blog";
import Contact from "../../components/Contact/Contact";
// import Social from "../../components/Social/Social";
import Footer from "../../components/Footer/footer";

class Index3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        // if adding/removing sections, make sure to update the ID order and follow the pattern
        { id: 1, idnm: "home", navheading: "Home" },
        { id: 2, idnm: "services", navheading: "Services" },
        // { id: 3, idnm: "features", navheading: "Innovations" },
        { id: 3, idnm: "who-we-are", navheading: "Who We Are" },
        { id: 4, idnm: "team", navheading: "Team" },
        // { id: 6, idnm : "pricing", navheading: "Pricing" },
        { id: 5, idnm: "contact", navheading: "Contact" },
      ],
      navClass: "",
      isRendered: false,
    };
  }

  componentDidMount() {
    this.fakeRequest().then(() => {
      this.setState({ isRendered: true });
    });
  }

  fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 1500));
  };
  render() {
    return (
      <React.Fragment>
        {this.state.isRendered === false ? (
          <>
            <div class="loader-container">
              <div class="loader"></div>
            </div>
          </>
        ) : (
          <>
            <NavbarPage
              navItems={this.state.navItems}
              navClass={this.state.navClass}
            />
            <Section />
            <Service sectionClass="pt-5" />
            {/* <AboutUs /> */}
            {/* uncomment to show section */}
            {/* <Pricing/> */}
            <WebsiteDesc />
            <Team />
            {/* <Process/> */}
            {/* <Testimonials/> */}
            {/* <GetStart/> */}
            {/* <Blog/> */}
            <Contact />
            <Footer />
          </>
        )}
      </React.Fragment>
    );
  }
}

export default Index3;
